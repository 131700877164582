

<template>
  <BackofficeBase :loader_prop="loader_prop"> </BackofficeBase>
</template>

<script>
import BackofficeBase from "./BackofficeBase";
import BackendModel from "../../models/BackendModel";

export default {
  name: "BackofficeLoginAsPoint",
  components: {
    BackofficeBase,
  },
  data() {
    return {
      loader_prop: true,
      view_data: [],
    };
  },
  async mounted() {
    this.id = this.$route.query.id;
    let backendModel = new BackendModel();
    let view_data = await backendModel.backendRequest(
      "/Api/service/backoffice/login_as_point",
      { id: this.id }
    );
    this.view_data = view_data.data.view_data;
    let session_id = this.view_data;
    let CookieDate = new Date();
    CookieDate.setFullYear(CookieDate.getFullYear() + 10);
    document.cookie =
      "point_session_id =" +
      session_id +
      "; expires=" +
      CookieDate.toUTCString() +
      ";";
    this.loader_prop = false;
    await this.$router.push({ path: "/point/point_home" });

  },
};
</script>

<style scoped>
@import "../../assets/style.css";
</style>

